<template>
  <div>
    <!-- 第一行 标题 -->
    <el-row>
      <el-col :span="24">
        <div
          class="grid-content center"
          style="
            font-size: 30px;
            color: #606266;
            font-weight: bold;
            letter-spacing: 3px;
            margin-top: 20px;
          "
        >
          评卷客户端管理
        </div>
      </el-col>
    </el-row>
    <!-- 第二行 说明 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content" style="color: #1890ff; font-size: 15px">
          操作说明：1、添加设备 2、设置科目对应设备 3、分配任务 4、缓存任务下发
        </div>
      </el-col>
    </el-row>
    <!-- 第三行 科目数量统计 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content">
          <div>
            <el-button
              v-throttle
              type="primary"
              size="medium"
              @click="startToCache(1)"
              >开始考生分配缓存</el-button
            >
            <el-button
              v-throttle
              type="primary"
              size="medium"
              style="margin-left: 20px"
              @click="startToCache(2)"
              >开始考生缓存</el-button
            >
            <el-button
              v-throttle
              type="danger"
              size="medium"
              style="margin-left: 20px"
              @click="clearToCache"
              >清除并暂停缓存</el-button
            >
            <el-button
              v-throttle
              type="primary"
              size="medium"
              style="margin-left: 20px"
              @click="refresh"
              >刷新统计</el-button
            >
          </div>
          <!-- 连接状态 -->
          <div style="margin-top: 15px">
            <span>连接状态:</span>
            <span style="margin-left: 20px">
              <span
                class="dot"
                style="background-color: #13ce66; margin-top: 8px"
              ></span>
              <span style="margin-left: 10px">已连接</span>
            </span>
            <span style="margin-left: 20px">
              <span
                class="dot"
                style="background-color: #f56c6c; margin-top: 8px"
              ></span>
              <span style="margin-left: 10px">未连接</span>
            </span>
          </div>
          <!-- 全选 -->
          <div style="margin-top: 10px">
            <el-checkbox v-model="allSelect">全选</el-checkbox>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 缓存列表面板 -->
    <el-row :gutter="25" style="margin-left: 20px; margin-right: 20px">
      <el-col
        class="el-col-25"
        v-for="(v, i) in dataList.length"
        :key="i"
        style="margin-top: 25px"
      >
        <div class="grid-content seatBlock">
          <el-checkbox
            v-model="checked[i]"
            style="margin-right: 220px; margin-top: 10px; margin-bottom: 10px"
          ></el-checkbox>
          <div
            style="
              position: absolute;
              font-size: 14px;
              margin-left: 10px;
              color: #606266;
              text-align: left;
            "
          >
            <div>物理:</div>
            <el-popover placement="top" width="200" trigger="hover">
              <div v-html="physicsOperationTest"></div>
              <div
                slot="reference"
                style="color: #00f; text-decoration: underline"
              >
                {{ dataList[i].subjectPhysicsVideoCompleteCount }}/{{
                  dataList[i].subjectPhysicsVideoTotalCount
                }}
              </div>
            </el-popover>

            <div style="margin-top: 3px">化学:</div>
            <el-popover placement="top" width="200" trigger="hover">
              <div v-html="hemistryOperationTest"></div>
              <div
                slot="reference"
                style="color: #00f; text-decoration: underline"
              >
                {{ dataList[i].subjectChemistryVideoCompleteCount }}/{{
                  dataList[i].subjectChemistryVideoTotalCount
                }}
              </div>
            </el-popover>

            <div style="margin-top: 3px">生物:</div>
            <el-popover placement="top" width="200" trigger="hover">
              <div v-html="biologyOperationTest"></div>
              <div
                slot="reference"
                style="color: #00f; text-decoration: underline"
              >
                {{ dataList[i].subjectBiologyVideoCompleteCount }}/{{
                  dataList[i].subjectBiologyVideoTotalCount
                }}
              </div>
            </el-popover>
          </div>
          <img src="@/assets/seatPic.png" alt />
          <span
            class="dot"
            :id="'changeDot' + [i]"
            style="
              background: #f56c6c;
              width: 12px;
              height: 12px;
              margin-top: -100px;
              margin-left: 108px;
            "
          ></span>
          <div
            style="
              color: #000000;
              font-size: 25px;
              font-weight: bold;
              margin-top: 10px;
            "
          >
            {{ v }}
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getClientInfo,
  startCache,
  clearCache,
  getClientSubjectApi,
  startAssignCacheApi
} from "@/api/marking/markingClient.js";
import { systemTime } from "@/api/system/information.js";
export default {
  data() {
    return {
      checked: [],
      //全选
      allSelect: false,
      //渲染列表
      dataList: [],
      //选中的列表
      checkedList: [],
      //考试ID
      allExamId: 0,
      //定时器
      timer: null,
      // 考题文本内容
      physicsOperationTest: "",
      biologyOperationTest: "",
      hemistryOperationTest: "",
      // 任务考题分配类型（1混合考题类型 2单一考题类型）
      taskTestDistributionType: 1,
    };
  },
  watch: {
    allSelect(newState, oldState) {
      if (newState == true) {
        this.checked.fill(true, 0, this.checked.length);
      } else {
        this.checked.fill(false, 0, this.checked.length);
      }
    },
  },
  mounted() {
    //获取路由传参
    this.allExamId = Number(this.$route.query.examId);
    this.InitClientInfomation();
    this.fetchExamTestContentInfo();
    //初始化选中列表
    this.timer = setInterval(() => {
      this.getClientInfomation();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },

  methods: {
    //首次获取客户端信息并渲染图表
    InitClientInfomation() {
      //获取服务器时间戳接口
      getClientInfo(this.allExamId).then((res) => {
        let { data, success } = res;
        if (success) {
          this.dataList = data.terminalCacheStatusList;
          this.taskTestDistributionType = data.taskTestDistributionType
          this.initList();
        }
        systemTime().then((res) => {
          let { data } = res;
          let serverTime = data;
          for (let i = 0; i < this.dataList.length; i++) {
            //对比时间戳 若是大于三秒判断状态为未连接
            if (serverTime - this.dataList[i].lastHeart < 15000) {
              document.getElementById(`changeDot${i}`).style.background =
                "#13CE66";
            } else {
              document.getElementById(`changeDot${i}`).style.background =
                "#F56C6C";
            }
          }
        });
      });
    },
    //获取客户端信息并渲染图表
    getClientInfomation() {
      //获取服务器时间戳接口
      getClientInfo(this.allExamId).then((res) => {
        let { data, success } = res;
        if (success) {
          this.dataList = data.terminalCacheStatusList;
        }
        systemTime().then((res) => {
          let { data } = res;
          let serverTime = data;
          for (let i = 0; i < this.dataList.length; i++) {
            //对比时间戳 若是大于三秒判断状态为未连接
            if (serverTime - this.dataList[i].lastHeart < 15000) {
              document.getElementById(`changeDot${i}`).style.background =
                "#13CE66";
            } else {
              document.getElementById(`changeDot${i}`).style.background =
                "#F56C6C";
            }
          }
        });
      });
    },
    // 获取弹出框文本内容
    fetchExamTestContentInfo() {
      getClientSubjectApi(this.allExamId).then((res) => {
        if (res.success) {
          const { data } = res;
          data.forEach((item) => {
            if (item.subject === 1) {
              item.subjectTestList.forEach((it, index) => {
                this.physicsOperationTest += `<div>${index + 1}.${
                  it.operationTestName
                }</div>`;
              });
            } else if (item.subject === 2) {
              item.subjectTestList.forEach((it, index) => {
                this.biologyOperationTest += `<div>${index + 1}.${
                  it.operationTestName
                }</div>`;
              });
            } else {
              item.subjectTestList.forEach((it, index) => {
                this.hemistryOperationTest += `<div>${index + 1}.${
                  it.operationTestName
                }</div>`;
              });
            }
          });
          if (!this.physicsOperationTest)
            this.physicsOperationTest = "<div>无考题</div>";
          if (!this.biologyOperationTest)
            this.biologyOperationTest = "<div>无考题</div>";
          if (!this.hemistryOperationTest)
            this.hemistryOperationTest = "<div>无考题</div>";
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },

    //初始化选中列表
    initList() {
      console.log(this.dataList.length, "length");
      for (let i = 0; i < this.dataList.length; i++) {
        this.checked[i] = false;
      }
    },

    //开始缓存
    startToCache(val) {
      this.checkedList = [];
      let apiMap = {
        1: startAssignCacheApi,
        2: startCache
      }
      let a = this.checked.keys();
      for (let x of a) {
        if (this.checked[x] == true) {
          this.checkedList.push(this.dataList[x]);
        }
      }
      console.log(this.checkedList, "clsd");
      if (this.checkedList.length == 0) {
        this.$message({
          message: "请选择设备",
          type: "error",
        });
        return;
      }
      if(this.taskTestDistributionType === 2 && !this.allSelect && val === 1) {
        this.$message({
          message: "单一考题类型需全选设备!",
          type: "error",
        });
        return;
      }
      this.$confirm(
        `当前已选择${this.checkedList.length}个设备，确定下发缓存任务吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }
      )
        .then(() => {
          apiMap[val](this.taskTestDistributionType === 2 ? this.filterSubjectVideo(this.checkedList) : this.checkedList).then((res) => {
            let { data, success } = res;
            if (success) {
              this.$message({
                type: "success",
                message: "缓存成功",
              });
            } else {
              this.$message({
                type: "error",
                message: "缓存失败",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消缓存",
          });
        });
    },

    //清除缓存
    clearToCache() {
      this.checkedList = [];
      let a = this.checked.keys();
      for (let x of a) {
        if (this.checked[x] == true) {
          this.checkedList.push(this.dataList[x]);
        }
      }
      if (this.checkedList.length == 0) {
        this.$message({
          message: "请选择设备",
          type: "error",
        });
        return;
      }
      this.$confirm(
        `当前已选择${this.checkedList.length}个设备，将清除设备对应的评卷客户端本地视频，确定清除吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          clearCache(this.checkedList).then((res) => {
            let { success } = res;
            if (success) {
              this.$message({
                type: "success",
                message: "清除缓存成功!",
              });
            } else {
              this.$message({
                type: "error",
                message: "清除缓存失败",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消清除缓存",
          });
        });
    },
    //刷新
    refresh() {
      this.getClientInfomation();
    },
    filterSubjectVideo(checkedList) {
      checkedList.forEach((it) => {
        if(!it.hasSubjectBiologyVideo) {
          it.appiontDistributionSubjectBiologTestList = []
        }
        if(!it.hasSubjectChemistryVideo) {
          it.appiontDistributionSubjectChemistryTestList = []
        }
        if(!it.hasSubjectPhysicsVideo) {
          it.appiontDistributionSubjectPhysicsTestList = []
        }
      })
      return checkedList
    }
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: rgba(223, 230, 236, 0.16);
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.center {
  text-align: center;
}

::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
}

::v-deep .el-checkbox__inner::after {
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  left: 7px;
  top: 3px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(50deg) scaleY(1.1);
}

.dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: #999999;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.el-col-25 {
  width: 20%;
  text-align: center;
}
.seatBlock {
  display: inline-block;
  width: 90%;
  height: 170px;
  border: 1px solid black;
  img {
    display: block;
    width: 90px;
    height: 72px;
    margin: auto;
  }
}
</style>
